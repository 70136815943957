var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"justify-center mb-5",style:({ width: '100%' })},[_c('div',{staticClass:"player"},[(_vm.isImage)?_c('DisplayImage',{attrs:{"maxHeight":_vm.maxImageHeight,"assetPath":_vm.preview.id,"fileName":_vm.preview.id,"contain":""}}):_vm._e(),(_vm.isVideo)?_c('Player',{key:'pl',attrs:{"media":{
        type: _vm.preview.mediaType || 'youtube',
        playerId: _vm.preview.id
      },"emit":['ended', 'play', 'pause'],"options":{
        autoplay: true,
        displayDuration: true,
        noCookie: false,
        rel: 0,
        showinfo: 0,
        iv_load_policy: 3,
        modestbranding: 1,
        muted: false,
        settings: ['captions'],
        captions: { active: true }
      }},on:{"ended":_vm.videoEnded,"pause":function($event){return _vm.$emit('videoPaused')},"play":_vm.videoPlay}}):_vm._e(),(_vm.isAudio)?_c('audio',{attrs:{"id":"player","controls":""}},[_c('source',{attrs:{"src":require("../../assets/AsimJayJayKarTune.mp3"),"type":"audio/mp3"}})]):_vm._e(),(_vm.isPdf)?_c('a',{staticClass:"pdf",attrs:{"target":"_blank","href":_vm.preview.id,"rel":"noopener noreferrer"}},[_c('DisplayImage',{attrs:{"maxHeight":_vm.maxImageHeight,"assetPath":_vm.preview.image,"fileName":_vm.preview.image,"contain":""}}),_c('div',{staticClass:"text-h6"},[_c('v-icon',{staticClass:"mr-2",attrs:{"medium":_vm.$vuetify.breakpoint.smAndDown,"large":!_vm.$vuetify.breakpoint.smAndDown}},[_vm._v("mdi-book-open-page-variant")]),_c('span',[_vm._v(_vm._s(_vm.$t('Click here to read')))])],1)],1):_vm._e()],1),_c('div',{staticClass:"text-center mt-10"},[_c('v-btn',{attrs:{"raised":"true","color":"secondary"},on:{"click":_vm.onNext}},[_vm._v(_vm._s(_vm.$t(_vm.preview.nextBtnText)))])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }